<template lang="pug">
div
  van-nav-bar(
    v-if="showNav"
    :title="navOption.title || this.$route.meta.title"
    :left-text="navOption.leftText"
    :left-arrow="navOption.leftArrow"
    :right-text="navOption.rightText"
    fixed
    @click-left="navOption.onClickLeft"
    @click-right="navOption.onClickRight"
  )
  keep-alive
    router-view(:style="calculateStyle" @on-nav="onNav" v-if="!$route.meta.noKeep" :key = '$route.fullPath')
  router-view(:style="calculateStyle" @on-nav="onNav" v-if="$route.meta.noKeep" :key = '$route.fullPath')
  //- .footer
    | 搭配家提供技术支持
  van-tabbar(v-if="$route.meta.showTab" v-model='activeIndex')
    van-tabbar-item(v-for="(tab, index) of powerTabData"
      :key='index'
      :icon='tab.icon' 
      :to='tab.to') {{tab.text}}
</template>

<script>
import { Tabbar, TabbarItem, NavBar  } from 'vant'



export default {
  components: {
    [Tabbar.name]: Tabbar,
    [TabbarItem.name]: TabbarItem,
    [NavBar.name]: NavBar
  },
  data () {
    return {
      showNav: true,
      navOption: {
        title: '',
        onClickLeft(){},
        onClickRight() {}
      },
      tabData: [
        {
          text: '首页',
          name: 'home',
          icon: 'home-o',
          to: {name: 'home'}
        },
        {
          text: '分类',
          name: 'category',
          icon: 'apps-o',
          to: {name: 'category'}
        },
        {
          text: '收藏',
          name: 'collect',
          icon: 'like-o',
          power: true,
          to: {name: 'collect'}
        },
        {
          text: '购物车',
          name: 'cart',
          icon: 'cart-o',
          power: true,
          to: {name: 'cart'}
        },
        {
          text: '个人中心',
          name: 'person',
          icon: 'user-o',
          to: {name: 'person'}
        }
      ],
      hallData: null
    }
  },
  computed: {
    // 必须登录且已经注册为公司销售员
    power() {
      // return this.$store.getters.isLogined() && this.$store.state.userInfo.sell_users
      return this.$store.getters.isLogined() && this.$store.state.userInfo.sell_users && this.$store.state.userInfo.sell_users.state == "1" 
    },
    powerTabData() {
       if(this.power) {
        return this.tabData
      } else {
        return this.tabData.filter(p=> !p.power)
      }
    },
    calculateStyle: {
      set: () => {},
      get: function () {
        let style = {}
        if (this.$route.meta.showTab) {
          Object.assign(style, {
            'margin-bottom': '50px'
          })
        }
        if (this.showNav) {
          Object.assign(style, {
            'margin-top': '46px'
          })
        }
        return style
      }
    },
    activeIndex: {
      set: () => {},
      get: function() {
        return this.tabData.findIndex((tab) => {
          return tab.name === this.$route.name 
        })
      }
      
    },
  },
  watch: {
    $route() {
      this.$set(this, 'navOption', {
        onClickLeft() {},
        onClickRight() {}
      })
    }
  },
  methods: {
    onNav(value) {
      if (!value.onClickRight) {
        value.onClickRight = () => {}
      }

      if (!value.onClickLeft) {
        value.onClickLeft = () => {}
      }
      this.$set(this, 'navOption', value)
    }
  },
  async created() {
  }
}
</script>

<style lang="less" scoped>
.footer {
  height: 50px;
}
</style>